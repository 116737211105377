var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('q-toolbar',{staticClass:"q-pr-none q-mb-md justify-end"},[_c('q-btn',{staticClass:"q-px-sm q-mx-sm",attrs:{"outline":"","icon":"mdi-filter-outline","color":"primary","no-caps":"","label":"Filtrar"},on:{"click":function($event){_vm.filter = !_vm.filter}}},[_c('q-tooltip',[_vm._v(" "+_vm._s(_vm.$t('filter_report')))])],1)],1),(_vm.filter)?_c('q-card',{staticClass:"q-mb-sm",attrs:{"flat":"","bordered":""}},[_c('q-toolbar',[_c('q-btn',{staticClass:"q-ml-auto",attrs:{"flat":"","round":"","dense":"","icon":"mdi-close"},on:{"click":function($event){_vm.filter = false}}})],1),_c('q-card-section',{staticClass:"q-pt-none row q-col-gutter-md"},[_c('div',{staticClass:"col-6"},[_c('q-input',{attrs:{"outlined":"","type":"text","label":_vm.$t('request_number')},model:{value:(_vm.params.orderId),callback:function ($$v) {_vm.$set(_vm.params, "orderId", $$v)},expression:"params.orderId"}})],1),_c('div',{staticClass:"col-3"},[_c('q-input',{attrs:{"outlined":"","type":"text","label":_vm.$t('conversion_id')},model:{value:(_vm.params.actionId),callback:function ($$v) {_vm.$set(_vm.params, "actionId", $$v)},expression:"params.actionId"}})],1),_c('div',{staticClass:"col-3"},[_c('q-select',{attrs:{"outlined":"","type":"text","options":[
            { label: 'CPA', value: 'cpa' },
            { label: 'CPL', value: 'cpl' },
            { label: 'Bônus', value: 'bonus' }
          ],"option-label":"label","option-value":"value","label":_vm.$t('type')},model:{value:(_vm.params.commissionType),callback:function ($$v) {_vm.$set(_vm.params, "commissionType", $$v)},expression:"params.commissionType"}})],1),_c('div',{staticClass:"col-6"},[_c('select-request',{attrs:{"v-model":_vm.params.advertiserId,"outlined":"","endpoint":"redirect?app=ADVERTISER&path=/api/get/advertiser","option-label":"trademark","option-value":"id","label":_vm.$t('fantasy_name'),"clearable":"","formatLabelAdvertiser":true},on:{"update":(value) => (_vm.params.advertiserId = value === null ? null : value.id)}})],1),_c('div',{staticClass:"col-6"},[_c('select-request',{attrs:{"v-model":_vm.params.campaignId,"outlined":"","endpoint":"/redirect?app=ADVERTISER&path=/api/get/campaign","option-label":"name","option-value":"id","label":_vm.$t('campaign'),"clearable":"","formatLabelCampaign":true},on:{"update":(value) => (_vm.params.campaignId = value === null ? null : value.id)}})],1),_c('div',{staticClass:"col-6"},[_c('date-input',{ref:"dateInput",attrs:{"buttons":"","outlined":"","label":_vm.$t('conversion_date'),"v-model":_vm.params.date,"clearable":"","today-btn":"","range":""},on:{"update":(value) => (_vm.params.date = value)}})],1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col"},[_c('q-select',{attrs:{"options":[
                {
                  label: _vm.$t('approved'),
                  value: 2
                },
                {
                  label: _vm.$t('pending'),
                  value: 1
                },
                {
                  label: _vm.$t('refused'),
                  value: 0
                }
              ],"label":_vm.$t('conversion_status'),"outlined":"","clearable":"","clear-icon":"mdi-close"},model:{value:(_vm.params.status),callback:function ($$v) {_vm.$set(_vm.params, "status", $$v)},expression:"params.status"}})],1)])]),_c('div',{staticClass:"col-6"},[_c('select-request',{attrs:{"v-model":_vm.params.affiliateId,"outlined":"","endpoint":"/redirect?app=affiliate&path=/api/get/affiliate/search","option-label":"name","option-value":"id","label":_vm.$t('affiliate_id'),"clearable":"","formatLabelAffiliate":true},on:{"update":(value) => (_vm.params.affiliateId = value === null ? null : value.id)}})],1),_c('div',{staticClass:"col-6"},[_c('select-request',{attrs:{"v-model":_vm.params.siteId,"outlined":"","endpoint":"/redirect?app=AFFILIATE&path=/api/v2/affiliate/site/list","option-label":"name","option-value":"id","label":_vm.$t('channel_name'),"clearable":"","formatLabelChannel":true},on:{"update":(value) => (_vm.params.siteId = value === null ? null : value.id)}})],1),_c('div',{staticClass:"col-6"},[_c('q-checkbox',{attrs:{"false-value":true,"true-value":false,"label":_vm.$t('dont_show_test_campaign')},model:{value:(_vm.params.test_campaigns),callback:function ($$v) {_vm.$set(_vm.params, "test_campaigns", $$v)},expression:"params.test_campaigns"}})],1),_c('div',{staticClass:"col-6 text-right"},[_c('q-btn',{staticClass:"q-px-md q-mx-md",attrs:{"color":"primary","label":_vm.$t('clear'),"no-caps":"","outline":""},on:{"click":_vm.clearFilter}}),_c('q-btn',{staticClass:"q-px-md",attrs:{"color":"positive","label":_vm.$t('search'),"no-caps":"","unelevated":""},on:{"click":_vm.onFilterReports}})],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }